<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    :data-testid="`newsletter-${uuid}`"
    :threshold="0.5"
    @enter="intersectPromotionView"
  >
    <div
      class="sb-blok-newsletter grid grid-cols-1 place-content-center"
      :class="[layout === 'horizontal' && 'lg:grid-cols-2']"
    >
      <div :class="contentClass">
        <Headline
          v-if="headline"
          v-bind="{ ...headline }"
          class="tracking-[-0.3px]"
          :class="[layout === 'horizontal' ? 'mb-4 text-3xl' : 'mb-1 text-lg']"
        >
          {{ headline.text }}
        </Headline>

        <Headline
          v-if="subHeadline"
          v-bind="{ ...subHeadline }"
          class="tracking-[-0.3px]"
          :class="[
            layout === 'horizontal'
              ? 'mb-8 text-5xl lg:text-6xl'
              : 'mb-5 text-2xl lg:text-3xl',
          ]"
        >
          {{ subHeadline.text }}
        </Headline>

        <div v-if="description" class="mb-4">
          <slot name="description">
            <ClientOnly>
              <RichTextRenderer :document="description.body" />
            </ClientOnly>
          </slot>
        </div>

        <template v-if="!emailSent">
          <form @submit.prevent="sendSubscription">
            <div class="mb-6 flex h-11 justify-between">
              <div class="relative mx-auto my-0 mr-2 grow">
                <span
                  class="absolute inset-y-0 left-0 flex items-center justify-center border-r border-neutral-30 px-2 py-1"
                >
                  <IconFielmannMail class="size-8" />
                </span>
                <input
                  id="newsletter-email-input"
                  v-model="email"
                  :class="{
                    'text-red-600': !isEmailValidated && !isNewInput,
                  }"
                  :placeholder="$t('newsletter.email')"
                  autocomplete="off"
                  class="size-full rounded border border-neutral-30 bg-white py-1.5 pl-3 pr-10 text-lg outline-none focus:border-black focus:ring-0"
                  type="email"
                  @input="onInput"
                  @blur="isNewInput = false"
                />
              </div>
              <FimButton
                type="primary"
                class="!gap-0 p-2"
                no-padding
                :loading="submitting"
                :disabled="submitting"
                @mousedown="clickObserver"
              >
                <template #icon>
                  <IconFielmannArrowRight class="size-6" />
                </template>
                <span class="sr-only">{{ $t('dialog.confirm') }}</span>
              </FimButton>
            </div>
          </form>

          <div
            v-if="annotations"
            :class="[
              layout === 'horizontal'
                ? 'text-sm leading-[1.285] text-gray-600'
                : 'text-sm leading-[1.285] text-neutral-40',
            ]"
          >
            <slot name="description">
              <ClientOnly>
                <RichTextRenderer :document="annotations.body" />
              </ClientOnly>
            </slot>
          </div>
        </template>
      </div>
      <div class="relative flex max-h-64 lg:max-h-none">
        <FimButton
          v-if="layout === 'vertical'"
          :aria-label="$t('banner.close')"
          class="absolute right-3 top-3 z-10 p-2"
          no-padding
          type="secondary"
          theme="dark"
          data-testid="newsletter-close"
          @click="$emit('update:open', false)"
          @mousedown="clickObserver"
        >
          <template #icon>
            <IconFielmannClose class="size-6" />
          </template>
        </FimButton>
        <CmsImage
          v-for="image in images"
          :key="image._uid"
          loading="lazy"
          :blok="image"
          sizes="(max-width: 1023px) 100vw, 70vw"
        />
      </div>
    </div>
  </Intersect>
</template>

<script setup lang="ts">
import type { SbNewsletter } from '../types/storyblok.gen'
import type { SbMultilinkObject } from '../types/storyblok'
import { Action } from '~/plugins/toast'
import { useToast } from '~/composables/useToast'

const props = defineProps({
  blok: {
    type: Object as PropType<SbNewsletter>,
    default: () => {},
  },
  uuid: {
    type: String as PropType<string>,
    default: '',
  },
  layout: {
    type: String as PropType<'horizontal' | 'vertical'>,
    default: 'horizontal',
  },
})

defineEmits(['update:open'])

const $i18n = useI18n()
const scope = effectScope()
onScopeDispose(() => scope.stop())
const { logger } = useLogging()
const [, setUserEmail] = useUserEmailState()
const $currentShop = useCurrentShop()
const { alert } = useToast()
const { trackNewsletter } = await useTrackingEvents()

const email = ref('')
const emailSent = ref(false)
const isNewInput = ref(true)

const isEmailValidated = computed(() => {
  return email.value.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
  )
})

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(email, (newValue) => {
    if (newValue === '') {
      isNewInput.value = true
    }
  })
})

const onInput = (event: Event) => {
  email.value = (event.target as HTMLInputElement).value
}

const getBlokUrl = (blokLink: SbMultilinkObject | undefined) => {
  if (!blokLink) {
    return ''
  }
  if (blokLink.linktype === 'story') {
    const base = window.location.toString()
    let path = blokLink.story?.slug ?? blokLink.cached_url ?? ''
    path += '/'
    return new URL(path, base).toString()
  }
  return blokLink.url
}

const submitting = ref<boolean>(false)
const sendSubscription = async () => {
  if (isEmailValidated.value) {
    submitting.value = true
    try {
      const countryCode = getIsoCountry($currentShop.value.locale)
      await useNewsletterSubscription({
        params: {
          occurredAt: new Date(),
          email: email.value,
          source: props.blok.source,
          unsubscribeRedirectUrl: getBlokUrl(
            props.blok?.unsubscribe_redirect_url,
          ),
          redirectUrl: getBlokUrl(props.blok?.subscribe_redirect_url),
          countryCode,
          languageCode: getNormalizedLocale($currentShop.value.locale, '_'),
        },
      })

      trackNewsletter(email.value)
      setUserEmail(email.value)
      emailSent.value = true
    } catch (error: unknown) {
      logger.error(error, {
        why: 'useNewsletterSubscription',
        where: 'Newsletter.vue',
      })

      alert($i18n.t('error.connection'), Action.error)
    }
  }
  submitting.value = false
}

const contentClass = computed(() => {
  if (props.layout === 'horizontal') {
    return [
      'order-2',
      'bg-gray-200',
      'px-6',
      'pt-10',
      'pb-16',
      'lg:px-20',
      'lg:py-24',
      'lg:order-none',
    ]
  } else {
    return ['order-2', 'bg-white', 'px-5 py-8', 'md:p-11']
  }
})

const headlineDefaults = { tag: HeadlineTag.H2 }

const headline = computed(() =>
  !emailSent.value
    ? parseHeadlineRef(props.blok.headline_ref, headlineDefaults)
    : parseHeadlineRef(props.blok.confirmation_headline_ref, headlineDefaults),
)
const subHeadline = computed(() =>
  !emailSent.value
    ? parseHeadlineRef(props.blok.subline_ref, headlineDefaults)
    : parseHeadlineRef(props.blok.confirmation_subline_ref, headlineDefaults),
)
const description = computed(() =>
  !emailSent.value
    ? parseTextRef(props.blok.description_ref)
    : parseTextRef(props.blok.confirmation_description_ref),
)
const images = computed(() =>
  !emailSent.value ? props.blok.images : props.blok.confirmation_images,
)

const annotations = computed(() => parseTextRef(props.blok.annotations_ref))

const { trackPromotionView, trackPromotionClick } = await useTrackingEvents()

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}

const clickObserver = () => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}
</script>
