export default (): [Ref<string>, (value: string) => void] => {
  const state = useState<string>('user-email-state', () => '')

  return [
    computed(() => state.value),
    (value: string) => {
      state.value = value
    },
  ]
}
